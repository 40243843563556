/*
PolicyInfo functions - will be exposed both on the client and server side.
Current limitations:
     - should ONLY work in the context of the PolicyInfo object (this = policyInfo object on which we are calling the function)
     - should not return promises
     - should not use external libraries which are not defined everywhere these functions are exposed. We currently expose *moment*.
     - should not overwrite existing policy info fields
     - should not reuse server side only pi functions
*/

// TODO: this should come from the backend. using document.write method.
(function (exports) {
    exports.get = function (moment) { // use get function to inject common modules
        return {
            collectedSum: function () {
                return this.charges.map(function (c) { return c.result ? c.sum : 0; }).reduce(function (a, b) { return a + b; }, 0);
            },
            remainingSum: function () {
                return this.term_quote.total - this.collectedSum();
            },
            dateFromEffectiveDate: function (amount, unit, returnAsString) {
                amount = amount || 0;
                unit = unit || 'days';
                const date = moment(this.effective_date, 'MM/DD/YYYY').add(amount, unit);
                return returnAsString ? date.format('MM/DD/YYYY') : date;
            },
            lastChargeIfFailed: function () {
                if (this.charges.length && this.charges[this.charges.length - 1].result === false) {
                    return this.charges[this.charges.length - 1];
                }
                return null;
            },
            getSmartHomeTrackingUrl: function (entry) {
                const kit = entry || this.getValue('smart_home.primary_kit') || {};
                if (['notion', 'kangaroo', 'simplisafe', 'ring', 'adt', 'ringflo'].indexOf(kit.provider) < 0) {
                    return `Unknown provider: ${kit.provider}`;
                }
                if (kit.tracking_code) {
                    if (kit.provider === 'notion') {
                        return `http://orders.getnotion.com/${kit.tracking_code}`;
                    }
                    const urlPrefix = {
                        ups: 'https://www.ups.com/track?loc=en_US&tracknum=',
                        usps: 'https://tools.usps.com/go/TrackConfirmAction?tLabels=',
                        fedex: 'https://www.fedex.com/apps/fedextrack/?tracknumbers=',
                    }[kit.carrier];
                    if (urlPrefix) {
                        return `${urlPrefix}${kit.tracking_code}`;
                    }
                }
                return 'No Tracking';
            },
            isUsingNewSmartHome: function () {
                return !!this.getValue('using_new_smart_home');
            },
        };
    };
})(window['policyInfoFunctions'] = {}); // generic exports for client and server
